import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

const NavItem = props => {
  const { to, icon, text, type } = props;
  const Icon = icon;
  const location = useLocation();
  const isActive =
    location.pathname === to || location.pathname.startsWith(`${to}/`);

  return (
    <Link
      to={to}
      className={`flex gap-4 items-center p-3 rounded-lg leading-tight transition-all duration-200 ${
        isActive
          ? "bg-darkgray text-white"
          : `hover:bg-darkgray hover:bg-opacity-80 hover:text-white ${
              type === "logout" && "hover:bg-wrgred"
            }`
      }`}
    >
      <Icon />
      {text}
    </Link>
  );
};

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default NavItem;
