import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import {
  ROLE_INTERVIEW_PANEL,
  ROLE_TALENT_ACQUISITION,
} from "../../helpers/constants";

const MainLayout = props => {
  const { isAuthenticated, role } = useSelector(state => state.auth);
  const location = useLocation();

  const routePermissions = {
    "/applications": [ROLE_TALENT_ACQUISITION],
    "/candidates": [ROLE_TALENT_ACQUISITION],
    "/interviews": [ROLE_TALENT_ACQUISITION, ROLE_INTERVIEW_PANEL],
    "/candidates/:id": [ROLE_TALENT_ACQUISITION],
    "/interviews/:id/evaluation": [
      ROLE_TALENT_ACQUISITION,
      ROLE_INTERVIEW_PANEL,
    ],
  };

  // Check if the current route requires specific roles
  const currentPath = Object.keys(routePermissions).find(path =>
    new RegExp(`^${path.replace(/:\w+/g, "[^/]+")}$`).test(location.pathname),
  );

  const shouldShowSidebar =
    isAuthenticated &&
    (!currentPath || routePermissions[currentPath].includes(role)) &&
    location.pathname !== "/unauthorized";

  return (
    <main>
      <Suspense>
        <div className="flex">
          {shouldShowSidebar && <Sidebar />}
          <div
            className={`${
              shouldShowSidebar ? "ml-64" : "ml-0"
            } flex-1 min-h-screen h-full ${
              shouldShowSidebar ? "w-[calc(100vw-16rem)]" : "w-full"
            }`}
          >
            {props.children}
          </div>
        </div>
      </Suspense>
    </main>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
