import React, { Fragment } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PropTypes from "prop-types";
import history from "./history";
import MainLayout from "../layouts/MainLayout/MainLayout";
import {
  ROLE_INTERVIEW_PANEL,
  ROLE_TALENT_ACQUISITION,
} from "../helpers/constants";
import { useSelector } from "react-redux";
import NotFound from "../pages/NotFound/NotFound";
import ErrorPage from "../pages/ErrorPage";

const routes = [
  {
    path: "/error",
    component: ErrorPage,
    layout: Fragment,
  },
  {
    path: "/",
    component: React.lazy(() => import("../pages/Login")),
    layout: MainLayout,
  },
  {
    path: "/applications",
    component: React.lazy(() => import("../pages/Applications")),
    layout: MainLayout,
    allowedRoles: [ROLE_TALENT_ACQUISITION],
  },
  {
    path: "/candidates",
    component: React.lazy(() => import("../pages/Candidates")),
    layout: MainLayout,
    allowedRoles: [ROLE_TALENT_ACQUISITION],
  },
  {
    path: "/interviews",
    component: React.lazy(() => import("../pages/Interviews")),
    layout: MainLayout,
    allowedRoles: [ROLE_TALENT_ACQUISITION, ROLE_INTERVIEW_PANEL],
  },
  {
    path: "*",
    component: NotFound,
    layout: Fragment,
  },
  {
    path: "/candidates/:id",
    component: React.lazy(() => import("../pages/CandidateProfile")),
    layout: MainLayout,
    allowedRoles: [ROLE_TALENT_ACQUISITION],
  },
  {
    path: "/loginRedirect",
    component: React.lazy(() => import("../pages/LoginRedirect")),
    layout: Fragment,
  },
  {
    path: "/unauthorized",
    component: React.lazy(() => import("../pages/Unauthorized")),
    layout: MainLayout,
  },
  {
    path: "/interviews/:id/evaluation",
    component: React.lazy(() => import("../pages/EvaluationPage")),
    layout: MainLayout,
    allowedRoles: [ROLE_TALENT_ACQUISITION, ROLE_INTERVIEW_PANEL],
  },
];

const Element = ({ component, layout, allowedRoles = [] }) => {
  const { isAuthenticated, role } = useSelector(state => state.auth);
  const UnauthorizedComponent = React.lazy(
    () => import("../pages/Unauthorized"),
  );
  let Component = UnauthorizedComponent;

  // Redirect logic for root path based on role when authenticated
  if (isAuthenticated) {
    if (location.pathname === "/") {
      if (role === ROLE_TALENT_ACQUISITION) {
        return <Navigate to="/applications" replace />;
      } else if (role === ROLE_INTERVIEW_PANEL) {
        return <Navigate to="/interviews" replace />;
      }
    }
  }

  if (allowedRoles.length === 0 || allowedRoles.includes(role)) {
    Component = component;
  }

  const Layout = layout || Fragment;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

Element.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.func,
  allowedRoles: PropTypes.array,
};

const createRoutes = routes => {
  return routes.map(route => {
    if (route.routes?.length) {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<Element {...route} />}
          index={route.index}
        >
          {createRoutes(route.routes)}
        </Route>
      );
    } else {
      // Render regular route
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<Element {...route} />}
          index={route.index}
        />
      );
    }
  });
};

export default function AppRouter() {
  return (
    <Router location={history.location} navigator={history}>
      <Routes>{createRoutes(routes)}</Routes>
    </Router>
  );
}
