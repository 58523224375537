import React from "react";
import NavItem from "../NavItem";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import ComputerIcon from "@mui/icons-material/Computer";
import { LogoutOutlined } from "@mui/icons-material";
import { logout } from "../../store/slice/authentication";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_TALENT_ACQUISITION } from "../../helpers/constants";

const Sidebar = () => {
  const role = useSelector(state => state.auth.role);
  const dispatch = useDispatch();
  return (
    <div className="fixed p-4 bg-clip-border bg-white text-gray-700 h-screen w-64 shadow-xl shadow-blue-gray-900/5 flex flex-col">
      <h5 className="p-4 block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-gray-900">
        ATS
      </h5>

      <div className="flex flex-col flex-grow">
        <nav className="flex flex-col gap-1 font-sans text-base font-normal text-gray-700">
          {role == ROLE_TALENT_ACQUISITION && (
            <>
              <NavItem
                to="/applications"
                icon={ArticleIcon}
                text="Applications"
              />
              <NavItem to="/candidates" icon={GroupsIcon} text="Candidates" />
            </>
          )}
          <NavItem to="/interviews" icon={ComputerIcon} text="Interviews" />
        </nav>
      </div>

      <div
        className="mt-auto"
        onClick={() => {
          dispatch(logout());
        }}
      >
        <NavItem to="/" icon={LogoutOutlined} text="Logout" type="logout" />
      </div>
    </div>
  );
};

export default Sidebar;
