import PropTypes from "prop-types";
import backgroundImage from "../../assets/images/background.jpg";

const NotFound = () => {
  const backgroundStyles = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div
      className="h-screen grid place-items-center bg-cover"
      style={backgroundStyles}
    >
      <div className="flex flex-col justify-center items-center gap-6 text-white backdrop-filter backdrop-blur rounded-lg bg-lightgray-300 bg-opacity-10 p-10 text-blue shadow-lg">
        <h1 className="text-wrgred text-8xl font-bold">404</h1>
        <p className="text-lg">{`Oops! The page you are looking for doesn't exist or has been moved.`}</p>
      </div>
    </div>
  );
};
NotFound.defaultProps = {
  className: "",
};
NotFound.propTypes = {
  className: PropTypes.string,
};
export default NotFound;
