import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import "./styles/globals.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="h-screen flex justify-center max-[600px]:mb-20 bg-white">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
            <ToastContainer
              position="bottom-right"
              theme="dark"
              autoClose={2000}
              hideProgressBar
              toastStyle={{ borderRadius: 8 }}
            />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  </React.StrictMode>,
);

reportWebVitals();
